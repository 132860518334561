import { IS_DEV, UPLOADS_FOLDER } from './contants';

const BASE_PATH = IS_DEV ? '/cetateafagarasului-v2/' : '/';

export const routes = {
  ro: {
    home: BASE_PATH,
    visiting: `${BASE_PATH}vizitare`,
    contact: `${BASE_PATH}contact`,
    contests: `${BASE_PATH}concursuri`,
    events: `${BASE_PATH}evenimente`,
    exhibitions: `${BASE_PATH}expozitii`,
    publications: `${BASE_PATH}publicatii`,
    news: `${BASE_PATH}noutati`,
    privacyPolicy: `${BASE_PATH}politica-de-confidentialitate`,
    termsAndConditions: `${BASE_PATH}termene-si-conditii`,
    cookies: `${BASE_PATH}cookies`,
    wealthDeclaration: `${BASE_PATH}declaratii-de-avere/`,
    salaryRights: `${BASE_PATH}drepturi-salariale/`,
    operatingRegulations: `https://cetateafagarasului.com/${UPLOADS_FOLDER}/2019/05/SKMBT_36190430094700.pdf`,
    codeOfConduct: `https://cetateafagarasului.com/${UPLOADS_FOLDER}/2019/08/Codul-de-conduita-07.07.2019.doc`,
    integrityDeclaration: `https://cetateafagarasului.com/${UPLOADS_FOLDER}/2022/03/SKMBT_36220328132600.pdf`,
    about: `${BASE_PATH}promovate/despre-muzeu/`,
    monthlyExhibit: `${BASE_PATH}exponatele-lunii/:exhibitId`,
    eventPage: `${BASE_PATH}evenimente/:eventId`,
    newsPage: `${BASE_PATH}noutati/:newsId`,
    exhibitionPage: `${BASE_PATH}expozitii/:exhibitionId`,
    publicationPage: `${BASE_PATH}publicatii/:publicationId`,
    recommendedArticle: `${BASE_PATH}recomandate/:recommendedArticleId`,
    fortressHistory: `${BASE_PATH}istoria-cetatii`,
    foreignTravellers: `${BASE_PATH}calatorii-straini`,
    fortressWeapons: `${BASE_PATH}armamentul-cetatii-fagarasului`,
    fortressMessengers: `${BASE_PATH}solii-in-cetatea-fagarasului`,
    virtualTour: `${BASE_PATH}tur-virtual`,
    qrCode: `${BASE_PATH}qr/:qrCodeId`,
    actaTerrae: `${BASE_PATH}anuar`,
    actaTerraeDetail: `${BASE_PATH}anuar/:id`,
    library: `${BASE_PATH}biblioteca`,
    libraryNewsPage: `${BASE_PATH}noutati-biblioteca/:libraryNewsId`,
  },
  en: {
    home: BASE_PATH,
    visiting: `${BASE_PATH}visit`,
    contact: `${BASE_PATH}contact-page`,
    contests: `${BASE_PATH}concursuri`,
    events: `${BASE_PATH}events`,
    exhibitions: `${BASE_PATH}exhibitions`,
    publications: `${BASE_PATH}publications`,
    news: `${BASE_PATH}news`,
    privacyPolicy: `${BASE_PATH}privacy-policy`,
    termsAndConditions: `${BASE_PATH}terms-and-conditions`,
    cookies: `${BASE_PATH}cookies-page`,
    about: `${BASE_PATH}promoted/about-the-museum/`,
    monthlyExhibit: `${BASE_PATH}monthly-exhibit/:exhibitId`,
    eventPage: `${BASE_PATH}events/:eventId`,
    newsPage: `${BASE_PATH}homepage-news/:newsId`,
    exhibitionPage: `${BASE_PATH}exhibitions/:exhibitionId`,
    publicationPage: `${BASE_PATH}publications/:publicationId`,
    recommendedArticle: `${BASE_PATH}recommended/:recommendedArticleId`,
    fortressHistory: `${BASE_PATH}fortresss-history`,
    foreignTravellers: `${BASE_PATH}foreign-travellers-about-fagaras-fortress`,
    fortressWeapons: `${BASE_PATH}the-armament-of-fagaras-fortress`,
    fortressMessengers: `${BASE_PATH}messengers-in-fagaras-fortress`,
    virtualTour: `${BASE_PATH}virtual-tour`,
    wealthDeclaration: `${BASE_PATH}declaratii-de-avere/`,
    salaryRights: `${BASE_PATH}drepturi-salariale/`,
    qrCode: `${BASE_PATH}qr/:qrCodeId`,
    actaTerrae: `${BASE_PATH}yearbook`,
    actaTerraeDetail: `${BASE_PATH}anuar-{:id}`,
    library: `${BASE_PATH}library`,
    libraryNewsPage: `${BASE_PATH}news-library/:libraryNewsId`,
  },
  de: {
    home: BASE_PATH,
    visiting: `${BASE_PATH}besuch`,
    contact: `${BASE_PATH}kontakt`,
    contests: `${BASE_PATH}concursuri`,
    events: `${BASE_PATH}veranstaltungen`,
    exhibitions: `${BASE_PATH}ausstellungen`,
    publications: `${BASE_PATH}veroeffentlichungen`,
    news: `${BASE_PATH}nachricht`,
    privacyPolicy: `${BASE_PATH}datenschutzrichtlinie`,
    termsAndConditions: `${BASE_PATH}geschaeftsbedingungen`,
    cookies: `${BASE_PATH}kekse`,
    about: `${BASE_PATH}gefoerdert/uber-das-museum/`,
    monthlyExhibit: `${BASE_PATH}monatsausstellung/:exhibitId`,
    eventPage: `${BASE_PATH}veranstaltungen/:eventId`,
    newsPage: `${BASE_PATH}homepage-neuigkeiten/:newsId`,
    exhibitionPage: `${BASE_PATH}ausstellungen/:exhibitionId`,
    publicationPage: `${BASE_PATH}veroeffentlichungen/:publicationId`,
    recommendedArticle: `${BASE_PATH}empfohlen/:recommendedArticleId`,
    fortressHistory: `${BASE_PATH}die-geschichte-der-fogarascher-festung`,
    foreignTravellers: `${BASE_PATH}auslandische-reisende-uber-die-fogarascher-festung`,
    fortressWeapons: `${BASE_PATH}die-bewaffnung-der-fogarascher-festung`,
    fortressMessengers: `${BASE_PATH}boten-in-der-fogarascher-festung`,
    virtualTour: `${BASE_PATH}virtual-tour`,
    wealthDeclaration: `${BASE_PATH}declaratii-de-avere/`,
    salaryRights: `${BASE_PATH}drepturi-salariale/`,
    qrCode: `${BASE_PATH}qr/:qrCodeId`,
    actaTerrae: `${BASE_PATH}yearbook`,
    actaTerraeDetail: `${BASE_PATH}anuar/:id`,
    library: `${BASE_PATH}bibliothek`,
    libraryNewsPage: `${BASE_PATH}neuigkeiten-bibliothek/:libraryNewsId`,
  },
};
