const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      suspense: false,
    },
  },
};

const QueryKeys = {
  Contests: 'Contests',
  FooterPosts: 'FooterPosts',
  Events: 'Events',
  EventsItem: 'EventItem',
  ExhibitionItem: 'ExhibitionItem',
  Exhibitions: 'Exhibitions',
  Library: 'Library',
  LibraryItem: 'LibraryItem',
  MainMenu: 'MainMenu',
  MonthlyExhibit: 'MonthlyExhibit',
  NewsItem: 'NewsItem',
  NewsPosts: 'NewsPosts',
  RecommendedArticle: 'RecommendedArticle',
  RecommendedArticles: 'RecommendedArticles',
  PublicationItem: 'PublicationItem',
  Publications: 'Publications',
  UniquePage: 'UniquePage',
  WealthDeclarations: 'WealthDeclarations',
  WorkingSchedule: 'WorkingSchedule',
  QRCode: 'QRCode',
  Yearbooks: 'Yearbooks',
  YearbooksByYear: 'YearbooksByYear',
};

export { queryClientConfig, QueryKeys };
