import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useGetLibraryNews from '../../hooks/useGetLibraryNews/useGetLibraryNews';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Pagination from '../../common/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import LibraryNewsItem from './components/LibraryNewsItem';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  LISTING_PAGE_OFFSET,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import { TLibraryNews } from '../../types/TGetLibraryNewsResponse';

import styles from './Library.module.scss';
import commons from '../../shared/styles/common.module.scss';

const Library = () => {
  const { t } = useTranslation();
  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  const [page, setPage] = useState<number>(1);

  const { language } = useGeneralDataContext();
  const {
    data: libraryNewsData,
    isLoading: isLoadingLibraryNews,
    refetch,
  } = useGetLibraryNews(LISTING_PAGE_OFFSET, language, page);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (libraryNewsData) {
      scrollToTop();
    }
  }, [libraryNewsData]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const gridTemplateRows = useMemo(() => {
    if (libraryNewsData && libraryNewsData.posts) {
      if (libraryNewsData.posts.length < LISTING_PAGE_OFFSET) {
        return Math.ceil(libraryNewsData.posts.length / 2);
      }
    }

    return 5;
  }, [libraryNewsData]);

  useSetPageTitle({
    pageTitle: `${t('pages.library')} - ${t('general.website-name')}`,
  });

  const returnPageContent = () => {
    if (libraryNewsData?.posts.length === 0) {
      return <div>{t('empty-state.no-results')}</div>;
    }

    return libraryNewsData?.posts.map((libraryNewsItem: TLibraryNews) => (
      <LibraryNewsItem
        key={libraryNewsItem.id}
        libraryNewsItem={libraryNewsItem}
      />
    ));
  };

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-tronului.jpeg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
      />
      <div className={classNames(styles.newsContainer, commons.container)}>
        <div className={styles.headingWrapper}>
          <h3 className={commons.subSectionHeading}>{t('pages.library')}</h3>
          <hr className={styles.headingLine} />
        </div>
        {isLoadingLibraryNews ? (
          <Spinner variant='brown' />
        ) : (
          <div
            className={styles.newsList}
            style={{
              gridTemplateRows: `repeat(${gridTemplateRows}, 430px)`,
            }}>
            {returnPageContent()}
          </div>
        )}
        <div className={styles.seeMoreWrapper}>
          <Pagination
            colorVariant='brown'
            currentPage={page}
            pageSize={LISTING_PAGE_OFFSET}
            totalCount={libraryNewsData?.total_number || 0}
            siblingCount={2}
            onPageChange={(currentPage) => setPage(currentPage)}
          />
        </div>
      </div>
    </div>
  );
};

export default Library;
