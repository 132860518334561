import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import useGetLibraryNewsItem from '../../hooks/useGetLibraryNewsItem/useGetLibraryNewsItem';
import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import { LOCAL_STORAGE_KEYS } from '../../config/constants/contants';

import placeholderImage from '../../images/placeholder.jpg';

import styles from './LibraryNewsPage.module.scss';
import commons from '../../shared/styles/common.module.scss';

const LibraryNewsPage = () => {
  const { t } = useTranslation();

  const { getLocalStorageItem } = useLocalStorage();

  const postId = getLocalStorageItem(LOCAL_STORAGE_KEYS.LIBRARY_NEWS_ID);

  const { data, refetch } = useGetLibraryNewsItem(Number(postId), false);

  useSetPageTitle({
    pageTitle: `${data?.title} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (postId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  return !data ? (
    <Spinner variant='green' />
  ) : (
    <div>
      <PageBanner
        title={data.title}
        imageSrc={data.featured_image || placeholderImage}
        imageText={data.title}
      />
      <div className={classNames(styles.libraryContainer, commons.container)}>
        <div>
          <h1
            className={classNames(
              styles.libraryTitle,
              commons.subSectionHeading,
            )}>
            {data.title}
          </h1>
          <div className={styles.libraryContent}>{parse(data.content)}</div>
          {data.featured_image && (
            <img
              src={data.featured_image}
              alt={data.title}
              className={styles.libraryContentImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LibraryNewsPage;
