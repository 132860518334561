import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetLibraryNewsItemResponse } from '../../types/TGetLibraryNewsItemResponse';

const useGetLibraryNewsItem = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } =
    request<TGetLibraryNewsItemResponse>(
      `${BASE_URL}/api/v1/library-news/${id}`,
      QueryKeys.LibraryItem,
      shouldQuery,
    );

  return { data, isLoading, error, refetch };
};

export default useGetLibraryNewsItem;
