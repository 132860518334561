import { IS_DEV } from './contants';

const BASE_PATH = IS_DEV ? '/cetateafagarasului-v2/' : '/';

const mainMenu = {
  ro: [
    {
      ID: 3169,
      title: 'Cetate',
      url: `${BASE_PATH}`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 3172,
      title: 'Istoria cetății',
      url: `${BASE_PATH}istoria-cetatii`,
      target: '',
      menu_item_parent: '3169',
    },
    {
      ID: 3195,
      title: 'Călători străini',
      url: `${BASE_PATH}calatorii-straini`,
      target: '',
      menu_item_parent: '3169',
    },
    {
      ID: 3218,
      title: 'Armamentul Cetății Făgărașului',
      url: `${BASE_PATH}armamentul-cetatii-fagarasului`,
      target: '',
      menu_item_parent: '3169',
    },
    {
      ID: 3217,
      title: 'Solii în Cetatea Făgărașului',
      url: `${BASE_PATH}solii-in-cetatea-fagarasului`,
      target: '',
      menu_item_parent: '3169',
    },
    {
      ID: 3177,
      title: 'Muzeu',
      url: '',
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 18,
      title: 'Evenimente',
      url: `${BASE_PATH}evenimente`,
      target: '',
      menu_item_parent: '3177',
    },
    {
      ID: 19,
      title: 'Expozitii',
      url: `${BASE_PATH}expozitii`,
      target: '',
      menu_item_parent: '3177',
    },
    {
      ID: 9367,
      title: 'Publicații',
      url: `${BASE_PATH}publicatii`,
      target: '',
      menu_item_parent: '3177',
    },
    {
      ID: 0,
      title: 'Anuar Acta Terrae Fogarasiensis',
      url: `${BASE_PATH}anuar`,
      target: '',
      menu_item_parent: '3177',
    },
    {
      ID: 20,
      title: 'Vizitare',
      url: `${BASE_PATH}vizitare`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 16,
      title: 'Contact',
      url: `${BASE_PATH}contact`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 10321,
      title: 'Tur virtual',
      url: `${BASE_PATH}tur-virtual`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 12080,
      title: 'Bibliotecă',
      url: `${BASE_PATH}biblioteca`,
      target: '',
      menu_item_parent: '0',
    },
  ],
  en: [
    {
      ID: 9750,
      title: 'Fortress',
      url: '#',
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 9758,
      title: 'Fortress’s history',
      url: `${BASE_PATH}fortresss-history`,
      target: '',
      menu_item_parent: '9750',
    },
    {
      ID: 9757,
      title: 'Foreign travelers about Făgăraş Fortress',
      url: `${BASE_PATH}foreign-travellers-about-fagaras-fortress`,
      target: '',
      menu_item_parent: '9750',
    },
    {
      ID: 9763,
      title: 'The armament of Făgăraș Fortress',
      url: `${BASE_PATH}the-armament-of-fagaras-fortress`,
      target: '',
      menu_item_parent: '9750',
    },
    {
      ID: 9759,
      title: 'Messengers in Făgăraș Fortress',
      url: `${BASE_PATH}messengers-in-fagaras-fortress`,
      target: '',
      menu_item_parent: '9750',
    },
    {
      ID: 9765,
      title: 'Museum',
      url: '#',
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 9754,
      title: 'Events',
      url: `${BASE_PATH}events`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 9766,
      title: 'Exhibitions',
      url: `${BASE_PATH}exhibitions`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 9761,
      title: 'Publications',
      url: `${BASE_PATH}publications`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 0,
      title: 'Acta Terrae Fogarasiensis Yearbook',
      url: `${BASE_PATH}yearbook`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 9764,
      title: 'Visit',
      url: `${BASE_PATH}visit`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 9752,
      title: 'Contact',
      url: `${BASE_PATH}contact-page`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 12082,
      title: 'Library',
      url: `${BASE_PATH}library`,
      target: '',
      menu_item_parent: '0',
    },
  ],
  de: [
    {
      ID: 10529,
      title: 'Stadt',
      url: '#',
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 10530,
      title: 'Die Geschichte der Fogarascher Festung',
      url: `${BASE_PATH}die-geschichte-der-fogarascher-festung`,
      target: '',
      menu_item_parent: '10529',
    },
    {
      ID: 10533,
      title: 'Ausländische Reisende über die Fogarascher Festung',
      url: `${BASE_PATH}auslandische-reisende-uber-die-fogarascher-festung`,
      target: '',
      menu_item_parent: '10529',
    },
    {
      ID: 10536,
      title: 'Die Bewaffnung der Fogarascher Festung',
      url: `${BASE_PATH}die-bewaffnung-der-fogarascher-festung`,
      target: '',
      menu_item_parent: '10529',
    },
    {
      ID: 10539,
      title: 'Boten in der Fogarascher Festung',
      url: `${BASE_PATH}boten-in-der-fogarascher-festung`,
      target: '',
      menu_item_parent: '10529',
    },
    {
      ID: 9765,
      title: 'Museum',
      url: '#',
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 10546,
      title: 'Veranstaltungen',
      url: `${BASE_PATH}veranstaltungen`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 10548,
      title: 'Ausstellungen',
      url: `${BASE_PATH}ausstellungen`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 9761,
      title: 'Veröffentlichungen',
      url: `${BASE_PATH}veroffentlichungen`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 0,
      title: 'Jahrbuch Acta Terrae Fogarasiensis',
      url: `${BASE_PATH}yearbook`,
      target: '',
      menu_item_parent: '9765',
    },
    {
      ID: 10542,
      title: 'Besuch',
      url: `${BASE_PATH}besuch`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 10544,
      title: 'Kontakt',
      url: `${BASE_PATH}kontakt`,
      target: '',
      menu_item_parent: '0',
    },
    {
      ID: 12082,
      title: 'Bibliothek',
      url: `${BASE_PATH}bibliothek`,
      target: '',
      menu_item_parent: '0',
    },
  ],
};

export default mainMenu;
