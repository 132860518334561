export const IS_DEV = window.location.href.includes('localhost');

export const DEV_BASE_URL = 'http://localhost/cetateafagarasului-v2/';
export const PROD_BASE_URL = 'https://cetateafagarasului.com/';
export const UPLOADS_FOLDER = 'wp-content/uploads';

export const BASE_APIS_URL = IS_DEV
  ? `${DEV_BASE_URL}/wp-json/wp/v2`
  : `${PROD_BASE_URL}/wp-json/wp/v2`;

export const BASE_URL = `${IS_DEV ? DEV_BASE_URL : PROD_BASE_URL}wp-json`;

export const MENUS = {
  EN: 'Main-EN',
  RO: 'Main-RO',
  DE: 'Main-DE',
};

export const AVAILABLE_LANGUAGES = {
  RO: 'ro',
  EN: 'en',
  DE: 'de',
};

export const LOCAL_STORAGE_KEYS = {
  EVENT_ID: 'event_id',
  EXHIBITION_ID: 'exhibition_id',
  LIBRARY_NEWS_ID: 'library_news_id',
  NEWS_ID: 'news_id',
  PUBLICATION_ID: 'publication_id',
  RECOMMENDED_ID: 'recommended_id',
  SELECTED_LANGUAGE: 'selected_language',
};

export const LISTING_PAGE_OFFSET = 10;

export const DEFAULT_IMAGE_ALT_TEXT = 'Cetatea Făgărașului';

export const PHONE_NUMBER = '0368 404 287 | 0739 084 056';
