import classNames from 'classnames';

import useLocalStorage from '../../../hooks/useLocalStorage/useLocalStorage';

import { TLibraryNews } from '../../../types/TGetLibraryNewsResponse';

import { LOCAL_STORAGE_KEYS } from '../../../config/constants/contants';

import placeholderImage from '../../../images/placeholder.jpg';

import commons from '../../../shared/styles/common.module.scss';
import styles from './LibraryNewsItem.module.scss';

type TProps = {
  libraryNewsItem: TLibraryNews;
};

const LibraryNewsItem = ({ libraryNewsItem }: TProps) => {
  const { setLocalStorageItem } = useLocalStorage();

  const savePostIdInLocalStorage = (id: number, link: string) => {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.LIBRARY_NEWS_ID, String(id));
    window.location.href = link;
  };

  return (
    <div
      className={classNames(
        styles.libraryNewsItemContainer,
        commons.container,
      )}>
      <div className={styles.libraryNewsItemContent}>
        <a
          key={libraryNewsItem.id}
          href={libraryNewsItem.link}
          onClick={(e) => {
            e.preventDefault();
            savePostIdInLocalStorage(libraryNewsItem.id, libraryNewsItem.link);
          }}
          className={styles.libraryNewsItemWrapper}>
          <div className={styles.libraryNewsItemText}>
            <h4 className={styles.libraryNewsItemTitle}>
              {libraryNewsItem.title}
            </h4>
            <p className={styles.libraryNewsItemExcerpt}>
              {libraryNewsItem.excerpt}
            </p>
          </div>
          <img
            src={libraryNewsItem.featured_image || placeholderImage}
            alt={libraryNewsItem.title}
            className={styles.libraryNewsItemImage}
          />
        </a>
      </div>
    </div>
  );
};

export default LibraryNewsItem;
