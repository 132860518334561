import React from 'react';
import { useTranslation } from 'react-i18next';

import useGetYearbooksByYear from '../../hooks/useGetYearbooksByYear/useGetYearbooksByYear';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../components/Icon/Icon';
import PageBanner from '../../common/PageBanner/PageBanner';
import Section from '../../components/Section/Section';
import Spinner from '../../components/Spinner/Spinner';
import YearbookByYearEntry from './components/YearbookByYearEntry';

import { getLanguage } from '../../helpers/helpers';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';
import { routes } from '../../config/constants/routes';

import { TYearbookByYear, TYearbookItem } from '../../types/TGetYearbookByYear';

import styles from './YearbookByYear.module.scss';

const YearbookByYear = () => {
  const { t } = useTranslation();

  const urlSplit = window.location.href.split('/');
  const year = urlSplit[urlSplit.length - 1].split('-')[1];
  const currentLanguage = getLanguage();
  const bannerURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  const { data, isLoading, refetch } = useGetYearbooksByYear(year);

  useSetPageTitle({
    pageTitle: `${t('yearbook.title')} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (year) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <div>
      <PageBanner
        imageSrc={`${bannerURL}${UPLOADS_FOLDER}/cetatea-fagaras-vechi.webp`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={`${t('yearbook.title')} ${year}`}
      />
      <div>
        {isLoading ? (
          <Spinner variant='green' />
        ) : (
          <>
            <div className={styles.backButton}>
              <a href={routes[currentLanguage].actaTerrae}>
                <Icon name='fa-chevron-left' color='icon-default' size={14} />
                <span>{t('yearbook.back-button')}</span>
              </a>
            </div>
            {data &&
              data.sections
                .sort((a: TYearbookItem, b: TYearbookItem) =>
                  a.sort_order > b.sort_order ? 1 : -1,
                )
                .map((section: TYearbookItem, index: number) => (
                  <Section
                    key={section.section_name}
                    title={section.section_name.toUpperCase()}
                    lineColor={index % 2 === 0 ? 'green' : 'purple'}
                    variant='light'
                    content={section.list
                      .sort(
                        (a: TYearbookByYear, b: TYearbookByYear) => a.id - b.id,
                      )
                      .map(
                        ({
                          id,
                          author,
                          file_url,
                          resume,
                          resume_title,
                          title,
                        }: TYearbookByYear) => (
                          <YearbookByYearEntry
                            key={id}
                            author={author}
                            file_url={file_url}
                            resume={resume}
                            resume_title={resume_title}
                            title={title}
                          />
                        ),
                      )}
                    image={undefined}
                    imageCaption={undefined}
                  />
                ))}
          </>
        )}
      </div>
    </div>
  );
};

export default YearbookByYear;
